import { RequestsTypes } from 'types/RequestsTypes';
import { UserTypes } from './UserTypes';
import { ResultsTypes } from './ResultsTypes';
import { DynamicsTypes } from './DynamicsTypes';
import { NewsTypes } from './NewsTypes';
import { OfficesTypes } from './OfficesTypes';
import { StartupTypes } from './StartupTypes';
import { AuthTypes } from './AuthTypes';
import { BannersTypes } from 'types/BannersTypes';
import { PreordersTypes } from 'types/PreordersTypes';

export enum TerritoryType {
    Russia = 'RUSSIA',
    Belarus = 'BELARUS'
}

export namespace AppTypes {
    export type State = {
        app: { actions: StateActions[] | {} };
        user: UserTypes.State;
        requests: RequestsTypes.State;
        results: ResultsTypes.State;
        dynamics: DynamicsTypes.State;
        news: NewsTypes.State;
        offices: OfficesTypes.State;
        startup: StartupTypes.State;
        auth: AuthTypes.State;
        banners: BannersTypes.State;
        preorders: PreordersTypes.State;
    };

    export type StateActions = {
        [key: string]: {
            request?: string;
            loading: boolean;
            success: boolean;
            payload?: any;
            error:
                | undefined
                | {
                      error?: string;
                      errors?: { field?: string; code?: string; message?: string }[];
                      exception?: string;
                      message?: string;
                      status?: number;
                      timestamp?: string;
                  };
        };
    };

    export type Action = {
        type: string;
        meta?: StateActions;
    };
}
