//@ts-nocheck
/* eslint-disable */
import localStorageService from 'services/LocalStorageService';
import { LKE_MATERIALS_ORDER_HISTORY } from 'redux/requests/constants';
import { SUPPORTED_TERRITORIES, TERRITORIES_BY_DOMAIN } from 'constants/Application';
import { TerritoryType } from 'types/AppTypes';

export function getOrigin() {
    return window.location.origin;
}

export function getHostname() {
    return window.location.hostname;
}

export function goTo(url: any, newTab = false) {
    if (url) {
        if (newTab) {
            window.open(url, '_blank');
        } else {
            window.location = url;
        }
    }
}

export function goBack() {
    window.history.back();
}

export function reload() {
    window.location.reload();
}

export function print() {
    window.print();
}

export function getCookie(name: any) {
    let matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[]\\\/+^])/g, '\\$1') + '=([^;]*)'));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const getAllOrderHistoryStorage = () => {
    const orderHistory = localStorageService.getItem(LKE_MATERIALS_ORDER_HISTORY);
    try {
        return orderHistory ? JSON.parse(orderHistory) : {};
    } catch (e) {
        return {};
    }
};

export const getOrderHistoryStorage = (officeId: string, userId: string) => {
    const orderHistory = getAllOrderHistoryStorage();
    return orderHistory[`${officeId}/${userId}`];
};

export function getHostnameTerritory(): TerritoryType {
    const parts = getHostname().split('.');
    const firstLevelDomain = parts[parts.length - 1];
    return TERRITORIES_BY_DOMAIN[firstLevelDomain] || SUPPORTED_TERRITORIES.RUSSIA;
}
