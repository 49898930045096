import { TerritoryType } from 'types/AppTypes';

export const LIMIT_ITEMS_DEFAULT = 10;

export const SUPPORTED_TERRITORIES = {
    RUSSIA: TerritoryType.Russia,
    BELARUS: TerritoryType.Belarus
};

export const TERRITORIES_BY_DOMAIN = {
    ru: SUPPORTED_TERRITORIES.RUSSIA,
    by: SUPPORTED_TERRITORIES.BELARUS
};
